 
<template>
  <vx-card slot="no-body" class="tabs-container px-6 pt-6" v-if="isRender">
    <vs-tabs v-model="activeTab" class="tab-action-btn-fill-conatiner">
      <vs-tab label="Basic" icon-pack="feather" icon="icon-flag">
        <!-- <div class="tab-text"> -->
        <car-basic-details class="mt-4" :data="carDetail" :handleConfirmation="confirmationStatus"></car-basic-details>
        <!-- </div> -->
      </vs-tab>
      <vs-tab label="Owner" icon-pack="feather" icon="icon-user">
        <div class="tab-text">
          <car-owner-details class="mt-4" :data="carDetail" />
        </div>
      </vs-tab>
      <vs-tab label="Selling" icon-pack="feather" icon="icon-shopping-bag">
        <div class="tab-text">
          <car-selling-details class="mt-4" :data="carDetail" />
        </div>
      </vs-tab>
      <vs-tab label="Photos" icon-pack="feather" icon="icon-image">
        <div class="tab-text">
          <image-carousel class="mt-4" :images="carDetail.images" />
        </div>
      </vs-tab>
      <template v-if="carDetail.isAuction">
        <vs-tab label="Auction" icon-pack="feather" icon="icon-user">
          <car-auction-details class="mt-4" :data="carDetail" />
        </vs-tab>
      </template>
    </vs-tabs>
  </vx-card>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";
import ImageCarousel from "./ImageCarousel.vue";
import CarBasicDetails from "./carBasicDetails.vue";
import CarOwnerDetails from "./carOwnerDetails.vue";
import CarSellingDetails from "./carSellingDetails.vue";
import CarAuctionDetails from "./carAuctionDetails.vue";

export default {
  name: "CarDetails",
  components: {
    ImageCarousel,
    CarBasicDetails,
    CarOwnerDetails,
    CarSellingDetails,
    CarAuctionDetails
  },
  data() {
    return {
      carDetail: {
        images: [],
        userId: {},
      },
      isRender: false,
      activeTab: 0,
      back() {
        this.$router.push({ name: "Vehicles" });
      },
    };
  },

  methods: {
    ...mapActions("admin", ["carDetails","handleNewCarListingStatus"]),
    getcarDetail(id) {
      let self = this;
      this.carDetails(id).then((res) => {
        this.carDetail = res.data.data;
        this.isRender = true;
      });
    },
    confirmationStatus(isRejected) {
      let data = {
            id: this.carDetail._id,
            isRejected,
      };
          this.handleNewCarListingStatus(data).then((res) => {
            if (res.data.data) {
              this.$vs.notify({
                title: "New car status changed",
                text: res.data.message,
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "success",
              });
              this.$router.go(-1);
            } else {
              this.$vs.notify({
                title: "Error",
                text: res.data.message,
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "danger",
              });
            }
          });
    }
  },

  created() {
    let carId = this.$route.params.id;
    this.getcarDetail(carId);
  },
};
</script> 
<style >
.ql-editor {
  min-height: 200px;
}
</style> 