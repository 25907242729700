<template>
  <vs-row v-if="data.sellerType==='private'">
    <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
      <div class="w-full m-5">
        <label for="userId.email">Email</label>
        <p id="userId.email">{{data.userId.email?data.userId.email:"N/A"}}</p>
      </div>
    </vs-col>
    <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
      <div class="w-full m-5">
        <label for="sellerType">OwnerShip</label>
        <p id="sellerType">Private</p>
      </div>
    </vs-col>

    <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
      <div class="w-full m-5">
        <label for="userId.firstName">First Name</label>
        <p id="userId.firstName">{{data.userId.firstName?data.userId.firstName:"N/A"}}</p>
      </div>
    </vs-col>
    <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
      <div class="w-full m-5">
        <label for="userId.lastName">Last Name</label>
        <p id="userId.lastName">{{data.userId.lastName?data.userId.lastName:"N/A"}}</p>
      </div>
    </vs-col>
  </vs-row>
  <vs-row v-else-if="data.sellerType==='dealership'">
    <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
      <div class="w-full m-5">
        <label for="name">Name</label>
        <p id="dealerId.name">{{data.dealerId.name?data.dealerId.name:"N/A"}}</p>
      </div>
    </vs-col>
    <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
      <div class="w-full m-5">
        <label for="sellerType">OwnerShip</label>
        <p id="sellerType">Dealer</p>
      </div>
    </vs-col>

    <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
      <div class="w-full m-5">
        <label for="dealerId.dealerID">Stock Number</label>
        <p id="dealerId.dealerID">{{data.stockNumber?data.stockNumber:"N/A"}}</p>
      </div>
    </vs-col>

    <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
      <div class="w-full m-5">
        <label for="dealerId.displayAddress">Display Address</label>
        <p id="dealerId.displayAddress">{{data.dealerId.displayAddress?data.dealerId.displayAddress:"N/A"}}</p>
      </div>
    </vs-col>
  </vs-row>
</template>


<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  filters: {
  // Filter definitions
  capitalize(string) {
    return string.charAt(0).toString().toUpperCase() + string.slice(1);
    }
  },
};
</script>