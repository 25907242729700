<template>
  <vs-row>
    <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
      <div class="w-full m-5">
        <label for="licencePlate">Licence Plate</label>
        <p id="licencePlate">
          {{ data.licencePlate ? data.licencePlate : "Refer Seller" }}
        </p>
      </div>
    </vs-col>
    <vs-col
      vs-type="flex"
      vs-justify="left"
      vs-align="left"
      vs-w="6"
      v-if="data.isPendingConfirmation == true"
    >
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="make">Maker</label>
          <p id="make">{{ data.make ? data.make : "Refer Seller" }}</p>
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="status">Confirmation</label>
          <div class="btn-container">
            <vs-button
              v-if="data.isAuction && auctionEndDateCaompare(data)"
              type="gradient"
              icon-pack="feather"
              color="success"
              class="status-btn"
              id="status"
              @click="confirmApproveAuction(data)"
            >
              Approve
            </vs-button>
            <vs-button
              v-else
              type="gradient"
              icon-pack="feather"
              color="success"
              class="status-btn"
              id="status"
              @click="handleConfirmation(false)"
            >
              Approve
            </vs-button>

            <vs-button
              type="gradient"
              icon-pack="feather"
              color="danger"
              class="status-btn"
              id="status"
              @click="handleConfirmation(true)"
              >Reject
            </vs-button>
          </div>
        </div>
      </vs-col>
    </vs-col>
    <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6" v-else>
      <div class="w-full m-5">
        <label for="make">Maker</label>
        <p id="make">{{ data.make ? data.make : "Refer Seller" }}</p>
      </div>
    </vs-col>
    <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
      <div class="w-full m-5">
        <label for="model">Model</label>
        <p id="model">{{ data.model ? data.model : "Refer Seller" }}</p>
      </div>
    </vs-col>

    <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
      <div class="w-full m-5">
        <label for="year">Year</label>
        <p id="year">{{ data.year ? data.year : "Refer Seller" }}</p>
      </div>
    </vs-col>

    <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
      <div class="w-full m-5">
        <label for="badge">Badge</label>
        <p id="badge">{{ data.badge ? data.badge : "Refer Seller" }}</p>
      </div>
    </vs-col>
    <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
      <div class="w-full m-5">
        <label for="series">Series</label>
        <p id="series">{{ data.series ? data.series : "Refer Seller" }}</p>
      </div>
    </vs-col>
    <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
      <div class="w-full m-5">
        <label for="transmission">Transmission</label>
        <p id="transmission">
          {{ data.transmission ? data.transmission : "Refer Seller" }}
        </p>
      </div>
    </vs-col>
    <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
      <div class="w-full m-5">
        <label for="engineCapacity">Engine Capacity</label>
        <p id="engineCapacity">
          {{ data.engineCapacity ? data.engineCapacity : "Refer Seller" }}
        </p>
      </div>
    </vs-col>
    <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
      <div class="w-full m-5">
        <label for="cylinder">Cylinder</label>
        <p id="cylinder">
          {{ data.cylinder ? data.cylinder : "Refer Seller" }}
        </p>
      </div>
    </vs-col>
    <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
      <div class="w-full m-5">
        <label for="vinNumber">Vin Number</label>
        <p id="vinNumber">
          {{ data.vinNumber ? data.vinNumber : "Refer Seller" }}
        </p>
      </div>
    </vs-col>
    <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
      <div class="w-full m-5">
        <label for="registrationState">Registration State</label>
        <p id="registrationState">
          {{ data.registrationState ? data.registrationState : "Refer Seller" }}
        </p>
      </div>
    </vs-col>
    <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
      <div class="w-full m-5">
        <label for="bodyType">Body Type</label>
        <p id="bodyType">
          {{ data.bodyType ? data.bodyType : "Refer Seller" }}
        </p>
      </div>
    </vs-col>
    <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
      <div class="w-full m-5">
        <label for="driveType">Drive Type</label>
        <p id="driveType">{{ data.drv ? data.drv : "Refer Seller" }}</p>
      </div>
    </vs-col>
    <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
      <div class="w-full m-5">
        <label for="description">Description</label>
        <p id="description" style="white-space: pre-wrap">
          {{ data.description ? data.description : "Refer Seller" }}
        </p>
      </div>
    </vs-col>
    <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
      <div class="w-full m-5">
        <label for="exteriorColor">Exterior Color</label>
        <p id="exteriorColor">
          {{ data.exteriorColor ? data.exteriorColor : "Refer Seller" }}
        </p>
      </div>
    </vs-col>
    <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
      <div class="w-full m-5">
        <label for="interiorColor">Interior Color</label>
        <p id="interiorColor">
          {{ data.interiorColor ? data.interiorColor : "Refer Seller" }}
        </p>
      </div>
    </vs-col>
    <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
      <div class="w-full m-5">
        <label for="fullServiceAvailable"
          >Full Service History Available?</label
        >
        <p id="fullServiceAvailable">
          {{ data.fullServiceAvailable | capitalize }}
        </p>
      </div>
    </vs-col>
    <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
      <div class="w-full m-5">
        <label for="hasWrittenOff">Has the vehicle been Written Off?</label>
        <p id="hasWrittenOff">{{ data.hasWrittenOff | capitalize }}</p>
      </div>
    </vs-col>
    <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
      <div class="w-full m-5">
        <label for="isRegistrationUptoDate">Registration Up to Date?</label>
        <p id="isRegistrationUptoDate">
          {{ data.isRegistrationUptoDate | capitalize }}
        </p>
      </div>
    </vs-col>
    <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
      <div class="w-full m-5">
        <label for="registrationExpiryDate">Registration Expiry Date</label>
        <p id="registrationExpiryDate">
          {{
            data.registrationExpiryDate
              ? moment(data.registrationExpiryDate).format("DD/MM/YYYY")
              : "Refer Seller"
          }}
        </p>
      </div>
    </vs-col>
  </vs-row>
</template>

<script>
import moment from "moment";

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
    handleConfirmation: { type: Function },
  },
  filters: {
    // Filter definitions
    capitalize(string) {
      if (string) {
        return string.charAt(0).toString().toUpperCase() + string.slice(1);
      } else {
        return "Refer Seller";
      }
    },
  },
  methods: {
    moment(date) {
      return moment(date);
    },
    confirmApproveAuction(data) {
      let auctionEndDateTime = new Date(data.auctionId.auctionEndDate);
      const auctionEndDate = moment(auctionEndDateTime).format("YYYY-MM-DD");

      const textMessage =
        auctionEndDateTime <= new Date()
          ? `The auction end date time ${auctionEndDate} has exceeded. Would you like to approve it?`
          : `The auction end date ${auctionEndDate} is approaching. kindly consider before approving it.`;

      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: "Auction Approve",
        text: textMessage,
        accept: () => {
          this.handleConfirmation(false);
        },
        acceptText: "Approve",
        cancel: true,
        disabled: true,
      });
    },
    auctionEndDateCaompare(data) {
      if (data.auctionId && data.auctionId.auctionEndDate) {
        let temp = new Date(data.auctionId.auctionEndDate);
        let currentDate = moment().format("YYYY-MM-DD");
        let auctionEndDate = moment(temp).format("YYYY-MM-DD");

        //if current date and time is equal.
        if (auctionEndDate === currentDate) {
          return true;
        }

        return false;
      }
      return true;
    },
  },
};
</script>
<style lang="scss" scoped>
.btn-container {
  display: flex;
  flex-wrap: wrap;
}
.status-btn {
  margin-right: 15px;
  margin-top: 10px;
}
</style>
