<template>
  <vs-row v-if="data.sellerType === 'private'">
    <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
      <div class="w-full m-5">
        <label for="userId.email">Lot #</label>
        <p id="userId.email">
          {{ data.auctionId ? data.auctionId.lotNumber : "N/A" }}
        </p>
      </div>
    </vs-col>
    <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
      <div class="w-full m-5">
        <label for="userId.email">Auction Status</label>
        <p id="userId.email">
          {{
            data.auctionId ? checkAuctionStatus(data.auctionId.status) : "N/A"
          }}
        </p>
      </div>
    </vs-col>
    <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
      <div class="w-full m-5">
        <label for="userId.email">Time Created</label>
        <p id="userId.email">
          {{
            data.auctionId ? getFormatedDate(data.auctionId.createdAt) : "N/A"
          }}
        </p>
      </div>
    </vs-col>
    <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
      <div class="w-full m-5">
        <label for="userId.email">Start Date</label>
        <p id="userId.email">
          {{
            data.auctionId
              ? data.auctionId.auctionStartDate
                ? getFormatedDate(data.auctionId.auctionStartDate)
                : "N/A"
              : "N/A"
          }}
        </p>
      </div>
    </vs-col>
    <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
      <div class="w-full m-5">
        <label for="userId.email">Time Approved</label>
        <p id="userId.email">
          {{
            data.auctionId
              ? data.auctionId.approvalTime
                ? getFormatedDate(data.auctionId.approvalTime)
                : "N/A"
              : "N/A"
          }}
        </p>
      </div>
    </vs-col>
    <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
      <div class="w-full m-5">
        <label for="userId.email">End Date</label>
        <p id="userId.email">
          {{
            data.auctionId
              ? data.auctionId.auctionEndDate
                ? getDateOnly(data.auctionId.auctionEndDate)
                : "N/A"
              : "N/A"
          }}
        </p>
      </div>
    </vs-col>
    <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
      <div class="w-full m-5">
        <label for="userId.email">End Time</label>
        <p id="userId.email">
          {{
            data.auctionId
              ? data.auctionId.auctionEndDate
                ? getTimeOnly(data.auctionId.auctionEndDate)
                : "N/A"
              : "N/A"
          }}
        </p>
      </div>
    </vs-col>
    <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
      <div class="w-full m-5">
        <label for="userId.email">Auction Period</label>
        <p id="userId.email">
          {{
            getDaysDifference(
              data.auctionId.auctionEndDate,
              data.auctionId.auctionStartDate
            )
          }}
          <!-- {{ data.auctionId ? data.auctionId.lotNumber : "N/A" }} -->
        </p>
      </div>
    </vs-col>
    <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
      <div class="w-full m-5">
        <label for="userId.email">Last Bid</label>
        <p id="userId.email">
          {{
            data.auctionId
              ? data.auctionId.latestBidAmount
                ? new Intl.NumberFormat("en-AU").format(
                    data.auctionId.latestBidAmount
                  )
                : "N/A"
              : "N/A"
          }}
        </p>
      </div>
    </vs-col>
    <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
      <div class="w-full m-5">
        <label for="userId.email">Number of bids</label>
        <p id="userId.email">
          {{ data.auctionId ? data.auctionId.totalNoOfBids : "N/A" }}
        </p>
      </div>
    </vs-col>
    <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
      <div class="w-full m-5">
        <label for="userId.email">Reserve Price</label>
        <p id="userId.email">
          $
          {{
            data.auctionId
              ? data.auctionId.reservePrice
                ? new Intl.NumberFormat("en-AU").format(
                    data.auctionId.reservePrice
                  )
                : "N/A"
              : "N/A"
          }}
        </p>
      </div>
    </vs-col>
    <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
      <div class="w-full m-5">
        <vs-button
          @click="biddingHistoryTablePopupActive = true"
          color="primary"
          type="border"
          >Bidding History</vs-button
        >
      </div>
    </vs-col>
    <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
      <div class="w-full m-5">
        <label for="userId.email">Auction Outcome</label>
        <p id="userId.email">
          {{
            data.auctionId ? formatAuctionOutcome(data.auctionId.status) : "N/A"
          }}
        </p>
      </div>
    </vs-col>
    <vs-popup
      class="holamundo"
      title="Bidding History"
      :active.sync="biddingHistoryTablePopupActive"
    >
      <template>
        <div class="center examplex">
          <table>
            <thead>
              <tr>
                <th>Username</th>
                <th>Email</th>
                <th>bidAmount</th>
                <th>Time</th>
                <th v-if="editButtonShow">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in historyData" :key="item._id">
                <td>{{ item.userId.username }}</td>
                <td>{{ item.userId.email }}</td>
                <td>
                  <p class="editedAmountContainer">
                    ${{ new Intl.NumberFormat("en-AU").format(item.bidAmount) }}
                    <span class="red" v-if="item.isEditedByAdmin">*</span>
                  </p>
                </td>

                <td>{{ getFormatedDate(item.createdAt) }}</td>
                <td v-if="editButtonShow">
                  <vs-button
                    color="danger"
                    v-if="editButtonShow && index === 0"
                    type="filled"
                    shadow
                    @click="handleEditBidAmountPopup(item._id, item.bidAmount)"
                  >
                    Edit
                  </vs-button>
                </td>
              </tr>
            </tbody>
          </table>
          <span class="red" v-if="hasEditedData"
            >* Bidding error corrected by Caroo admin</span
          ><br />
          <span class="mr-2"
            >{{
              currentPage * dataTableParams.limit - (dataTableParams.limit - 1)
            }}
            -
            {{
              totalDocs - currentPage * dataTableParams.limit > 0
                ? currentPage * dataTableParams.limit
                : totalDocs
            }}
            of {{ totalDocs }}</span
          >
          <vs-pagination
            :total="totalPage"
            v-model="dataTableParams.page"
          ></vs-pagination>
        </div>
      </template>
    </vs-popup>
    <vs-popup
      class="holamundo"
      title="Edit Bid Amount"
      :active.sync="editBidAmountPopupActive"
    >
      <template>
        <vs-input
          val-icon-success="done"
          val-icon-danger="error"
          data-vv-validate-on="blur"
          data-vv-as="first name"
          name="bidAmount"
          label-placeholder="Bid Amount"
          placeholder="Bid Amount"
          v-model="bidAmount"
          class="w-full mt-6"
        />
        <span class="text-danger text-sm">{{ saveError }}</span
        ><br />
        <vs-button
          color="danger"
          type="filled"
          shadow
          @click="handlePasswordPopup"
        >
          Save
        </vs-button>
      </template>
    </vs-popup>
    <vs-popup
      class="holamundo"
      title="Enter password"
      :active.sync="passwordPopUpActive"
    >
      <template>
        <vs-input
          data-vv-validate-on="blur"
          v-validate="'required'"
          name="password"
          label-placeholder="Admin Password"
          placeholder="Admin Password"
          icon-no-border
          icon-pack="feather"
          v-model="password"
          class="w-full"
          :icon="passwordIcon"
          :type="passwordType"
          @click.native="showHidePassword($event)"
        />
        <span class="text-danger text-sm">{{ saveError }}</span
        ><br />
        <vs-button
          color="danger"
          type="filled"
          shadow
          @click="checkAdminPassword"
        >
          Next
        </vs-button>
      </template>
    </vs-popup>
  </vs-row>
</template>

<script>
import { auctionStatus } from "../../components/constants/enums";
import { mapActions } from "vuex";
import moment from "moment";

export default {
  data() {
    return {
      totalDocs: 0,
      currentPage: 1,
      dataTableParams: {
        page: 1,
        limit: 10,
      },
      biddingHistoryTablePopupActive: false,
      editBidAmountPopupActive: false,
      historyData: [],
      toEditBidId: "",
      saveError: "",
      bidAmount: 0,
      hasEditedData: false,
      auctionId: "",
      editButtonShow: true,
      password: "",
      passwordPopUpActive: false,
      passwordType: "password",
      passwordIcon: "icon icon-eye-off",
    };
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  filters: {
    // Filter definitions
    capitalize(string) {
      return string.charAt(0).toString().toUpperCase() + string.slice(1);
    },
  },
  mounted() {
    this.getBiddingHistoryData();
  },
  methods: {
    ...mapActions("admin", [
      "handleCarBiddingHistory",
      "handleBidAmountChange",
      "handleCheckPassword",
    ]),

    //get Date differenct
    getDaysDifference(day1, day2) {
      //const date = moment(day1).format("YYYY-MM-DD");
      //const date2 = moment(day2).format("YYYY-MM-DD");
      const date = moment(day1);
      const date2 = moment(day2);
      const diffDays = moment(date).diff(moment(date2), "days");
      const diffHours = moment(date).diff(moment(date2), "hours");
      const diffMinutes = moment(date).diff(moment(date2), "minutes");

      let dayText = "Day";
      if (diffDays > 1) {
        dayText = "Days";
      }

      if (diffDays) {
        return diffDays + " " + dayText;
      } else if (diffHours) {
        return diffHours + "H ";
      } else if (diffMinutes) {
        return diffMinutes + "M";
      } else {
        return "NAN";
      }
    },
    showHidePassword(event) {
      if (
        event.target.className ===
          "vs-icon notranslate icon-scale icon-inputx notranslate vs-input--icon feather icon icon-eye-off null icon-no-border" ||
        event.target.className ===
          "vs-icon notranslate icon-scale icon-inputx notranslate vs-input--icon feather icon icon-eye null icon-no-border"
      ) {
        this.showPassword = !this.showPassword;
        this.passwordType === "password"
          ? (this.passwordType = "text")
          : (this.passwordType = "password");
        this.passwordIcon === "icon icon-eye-off"
          ? (this.passwordIcon = "icon icon-eye")
          : (this.passwordIcon = "icon icon-eye-off");
      }
      return true;
    },
    getBiddingHistoryData() {
      let self = this;
      this.dataTableParams.id = this.data.auctionId._id;
      if (
        this.data.auctionId.status ===
          auctionStatus.COMPLETED_RESERVED_NOT_MET ||
        this.data.auctionId.status === auctionStatus.COMPLETED_SOLD
      ) {
        this.editButtonShow = false;
      }
      this.handleCarBiddingHistory(this.dataTableParams).then((res) => {
        this.historyData = res.data.data.docs;
        this.hasEditedData = res.data.data.docs.some(
          (object) => object.isEditedByAdmin === true
        );
        self.historyData = res.data.data.docs;
        self.totalDocs = res.data.data.pagination.total;
        self.page = res.data.data.pagination.page;
        self.currentPage = self.page;
      });
    },
    handleEditBidAmountPopup(bidId, bidAmount) {
      this.biddingHistoryTablePopupActive = false;
      this.editBidAmountPopupActive = true;
      this.bidAmount = bidAmount;
      this.toEditBidId = bidId;
    },
    handlePasswordPopup() {
      if (this.bidAmount.match(/^[0-9]+$/) != null) {
        this.passwordPopUpActive = true;
        this.editBidAmountPopupActive = false;
      }
    },
    checkAdminPassword() {
      const data = {
        password: this.password,
      };
      this.handleCheckPassword(data)
        .then((res) => {
          this.$vs.loading.close();
          if (res.data.data) {
            this.passwordPopUpActive = false;
            this.password = "";
            this.editBidAmountPopupActive = false;
            let obj = this.historyData.find((o) => o._id === this.toEditBidId);
            if (obj) {
              obj.bidAmount = this.bidAmount;
              obj.isEditedByAdmin = true;
            }
            this.handleSaveNewBidAmount();
          }
        })
        .catch((error) => {
          this.$vs.notify({
            title: "Error",
            text: "Incorrect Password",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        });
    },
    handleSaveNewBidAmount() {
      if (this.bidAmount.match(/^[0-9]+$/) != null) {
        this.saveError = "";
        let auctionId = this.data.auctionId._id;
        const data = {
          id: this.toEditBidId,
          newBidAmount: this.bidAmount,
          auctionId,
        };
        this.handleBidAmountChange(data).then((res) => {
          this.$vs.loading.close();
          if (res.data.data) {
            this.editBidAmountPopupActive = false;
            this.biddingHistoryTablePopupActive = true;
            this.hasEditedData = true;
            this.$vs.notify({
              title: "Car Updated",
              text: res.data.message,
              iconPack: "feather",
              icon: "icon-alert-circle",
              message: "success",
            });
          } else {
            this.$vs.notify({
              title: "Error",
              text: res.data.message,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
            });
          }
        });
      } else {
        this.saveError = "Bid Amount can only be numbers";
      }
    },
    checkAuctionStatus(status) {
      let currentDate = new Date();
      let returnStatus;
      const { auctionStartDate, auctionEndDate } = this.data.auctionId;

      if (
        currentDate >= new Date(auctionStartDate) &&
        currentDate <= new Date(auctionEndDate) &&
        status !== auctionStatus.PENDING_APPROVAL
      )
        returnStatus = "Live";
      if ([auctionStatus.PENDING_APPROVAL].includes(status))
        returnStatus = "Pending";
      if (
        [
          auctionStatus.COMPLETED_SOLD,
          auctionStatus.COMPLETED_RESERVED_NOT_MET,
        ].includes(status)
      )
        returnStatus = "Completed";
      return returnStatus;
    },
    openBidHistoryPopUp() {
      this.openBidHistory = true;
    },
    formatAuctionOutcome(status) {
      switch (status) {
        case auctionStatus.COMPLETED_SOLD:
          return "Completed - Sold";
        case auctionStatus.COMPLETED_RESERVED_NOT_MET:
          return "Completed - Reserve not met";
        case auctionStatus.LIVE_RESERVE_MET:
          return "Live (reserve met)";
        case auctionStatus.LIVE_RESERVE_NOT_MET:
          return "Live (reserve not met)";
        case auctionStatus.LIVE:
          return "Live";
        default:
          return "Pending";
      }
    },
    getDateOnly(date) {
      return new Date(date).toISOString().split("T")[0];
    },
    getTimeOnly(date) {
      let temp = new Date(date);
      let hour = temp.getHours();
      let minutes = temp.getMinutes();
      let amPm = hour < 12 ? "AM" : "PM";
      hour = hour % 12;
      hour = hour ? hour : 12;
      let time = `${hour}:${minutes < 10 ? "0" + minutes : minutes} ${amPm}`;
      return time;
    },
    getFormatedDate(date) {
      let temp = new Date(date);
      let day = temp.getDate();
      let month = temp.getMonth() + 1;
      let year = temp.getFullYear();
      let dateOnly = year + "-" + month + "-" + day;
      let hour = temp.getHours();
      let minutes = temp.getMinutes();
      let amPm = hour < 12 ? "AM" : "PM";
      hour = hour % 12;
      hour = hour ? hour : 12;
      let time = `${hour}:${minutes < 10 ? "0" + minutes : minutes} ${amPm}`;
      return dateOnly + " " + time;
    },
  },
  computed: {
    totalPage: function () {
      return this.totalDocs / this.dataTableParams.limit >
        parseInt(this.totalDocs / this.dataTableParams.limit)
        ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
        : parseInt(this.totalDocs / this.dataTableParams.limit);
    },
  },
  watch: {
    "dataTableParams.page": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dataTableParams.page = newVal;
        this.getBiddingHistoryData();
      }
    },
  },
};
</script>
<style>
tr:nth-child(even) td {
  background-color: #f2f2f2;
}

.holamundo .vs-popup {
  width: 50%;
}

table {
  width: 100%;
}
td {
  padding-left: 10px;
  height: 50px;
}
.red {
  color: red;
}
.editedAmountContainer {
  display: inline-block;
}
</style>
